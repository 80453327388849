import React from 'react';
import safeNavigate from 'apputil/safe-navigate';
import loadable from '@loadable/component';
import queryString from 'query-string';
import { LIVE } from 'gatsby-env-variables';

import { HOME_LOGGED_IN_ROUTE, LANDING_PAGE } from 'constants/navigation';

import { SkeletonHomeView, SkeletonHomeAdvertisingView } from 'views/home/skeleton';

import { isAuthenticated } from 'services/authentication-service';

import SkeletonLandingView from 'views/landing/skeleton';

const RegistrationStartPage = loadable(() => import('suspended-page/registration'));

const RegistrationPage = ({ location = {} }) => {
  const { search = '' } = location;
  const { sub = 'start' } = queryString.parse(search) || {};

  const authenticated = isAuthenticated();
  if (!LIVE) {
    safeNavigate(LANDING_PAGE);
    return (<SkeletonHomeAdvertisingView />);
  }

  if (authenticated) {
    safeNavigate(HOME_LOGGED_IN_ROUTE);
    return (<SkeletonHomeView />);
  }

  return (
    <RegistrationStartPage fallback={<SkeletonLandingView/>} sub={sub}/>
  );
};

export default RegistrationPage;
